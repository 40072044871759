import React from "react";

const Error = () => {
  return (
    <div className="flex justify-center flex-col items-center thankyou">
      <div>
        <img src="/images/error.png" alt="" />
      </div>
      <span className="text-[50px] font-poppins-600 text-dark_black">
        404 Error
      </span>
      <span className="text-[16px] font-poppins-500 text-secondary_2">
        Sorry, page not, found
      </span>
    </div>
  );
};

export default Error;
