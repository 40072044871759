import { axiosInstance, axiosInstancePublic } from "./intercepter";

export const getCurrentCampaign = async (id: any) => {
  try {
    const url = id ? `/campaign/current/${id}` : "/campaign/current";
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error: any) {
    return error.response;
  }
};

export const makePayment = async (data: any) => {
  try {
    const response = await axiosInstancePublic.post("/donation/payment", data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const paypalPayment = async (data: any) => {
  try {
    const response = await axiosInstancePublic.post(
      "/donation/paypal/payment",
      data
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const paypalPaymentSuccess = async (data: any) => {
  try {
    const response = await axiosInstancePublic.post(
      "/donation/payment/success",
      data
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getThankyouDetails = async (id: any) => {
  try {
    const response = await axiosInstancePublic.get(`/donation/thankyou/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCurrentCampaignReport = async (id: any) => {
  try {
    const url = id ? `/campaign/report/${id}` : "/campaign/report";
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const verifyCaptcha = async (token: any) => {
  try {
    const response = await axiosInstance.post("/auth/verify-captcha", {
      token: token,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
