import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { achievement } from "../../utils";
import GameBundle from "./components/videos-bundles/GameBundle";
import PaymentBoardCard from "./components/paymentBoard/PaymentBoardCard";
import Achievement from "./components/achievment/Achievment";
import DocumentTitle from "../../components/DocumentTitle";
import { getCurrentCampaign, getCurrentCampaignReport } from "../../../service";
import { useNavigate, useParams } from "react-router-dom";

const Campaign = () => {
  const [campaignData, setCampaignData]: any = useState({});
  const [amount, setAmount] = useState(0);
  const [campaignReports, setCampaignReports]: any = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getCampaigns();
    getCampaignReport();
  }, []);

  const getCampaignReport = async () => {
    const responses = await getCurrentCampaignReport(id);
    setCampaignReports(responses?.data);
  };

  const getCampaigns = async () => {
    try {
      const response = await getCurrentCampaign(id);
      if (response?.status === 404) {
        navigate("/error/404");
        return;
      }
      setCampaignData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  DocumentTitle("Game bundle", "GenerOZity Bundle");
  return (
    <>
      <div className="relative">
        <Header campaignData={campaignData} campaignReports={campaignReports} />
        <div className="container mx-auto flex flex-col gap-8">
          <div className="grid lg:grid-cols-12 md:grid-cols-12 gap-4 sxs:gap-4">
            <div className="lg:col-span-7 md:col-span-12 col-span-12">
              <div className="flex items-center justify-center">
                <div className="flex flex-col gap-8">
                  <div className="flex flex-col gap-1">
                    <div>
                      <img
                        src={campaignData?.image}
                        alt="video-board"
                        className="max-w-full max-h-full bg-no-repeat bg-center"
                      />
                    </div>
                    <div
                      className="lg:max-h-[780px] xl:max-h-[895px] md:max-h-[1050px] sm:max-h-[1050px] h-auto px-2 md:flex md:justify-center overflow-y-scroll py-2"
                      id="bundle-card"
                    >
                      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-7 lg:gap-3 xl:gap-7 flex justify-center">
                        {campaignData?.gamesDetails?.map(
                          (i: any, index: number) => {
                            const status =
                              i.freeStatusCount === 0
                                ? "Sold Out"
                                : amount >= i?.price
                                ? "unlocked"
                                : "locked";
                            return (
                              <GameBundle
                                bundle={i}
                                key={index}
                                status={status}
                                setAmount={setAmount}
                              />
                            );
                          }
                        )}
                        <div className="h-[6px]" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:col-span-5 md:col-span-12 lg:mt-[-350px] bg-secondary_1 rounded-[15px] col-span-12 mr-4">
              <PaymentBoardCard
                campaignData={campaignData || {}}
                amount={amount}
                setAmount={setAmount}
                highestAmount={campaignReports?.highestAmount}
              />
            </div>
          </div>
          <div className="border-b-[2px] border-light_low_gray"></div>
          <div className="flex flex-col gap-8 items-center px-4">
            <div className="grid lg:grid-cols-4 md:grid-cols-2 lg:gap-4 xl:gap-8 gap-8 mb-[40px]">
              {Object.keys(campaignReports || {}).map(
                (key: any, index: number) => {
                  return (
                    !["currentDonation", "highestAmount"].includes(key) && (
                      <Achievement
                        achievement={{
                          details: campaignReports[achievement[index]?.key],
                          title: achievement[index].title,
                          icon: achievement[index].icon,
                        }}
                        key={index}
                      />
                    )
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Campaign;
