import React from "react";
import { buttonClassMapping, capitalize } from "../../../../utils";

const GameBundle = ({
  bundle,
  currentPage = "campaign",
  status,
  setAmount,
}: any) => {
  const { image, description, freeStatusCount, platform, keyCode } = bundle;
  return (
    <>
      <div
        className={` lg:w-[172px] xl:w-[210px] w-[250px] shadow-bundle-shadow flex flex-col items-center lg:pt-2 xl:pt-2 pt-5 lg:px-2 xl:px-2 px-4 pb-4 rounded-[15px] ${
          currentPage === "campaign" &&
          `bg-[${status === "sold_out" ? "#D9D9D980" : "#fff"}]`
        }`}
      >
        <div className="flex flex-col h-full justify-between gap-4">
          <div className="flex flex-col gap-4">
            <div>
              <img className="rounded-[12px]" src={image} alt={platform} />
            </div>
            <div className="flex flex-col gap-2">
              <span className="lg:text-[10px] xl:text-[12px] text-[14px] lg:leading-[16px] leading-[21px] text-dark_gray font-poppins-500">
                {platform}
              </span>
              <p className="lg:text-[10px] xl:text-[12px] text-[14px] lg:leading-[16px] leading-[21px] text-secondary_2 font-poppins-400">
                {currentPage === "campaign" ? description : keyCode}
              </p>
            </div>
          </div>
          <div>
            {currentPage === "campaign" && (
              <div className="flex justify-between items-center">
                <button
                  className={`lg:text-[10px] xl:text-[12px] text-[14px] lg:leading-[16px] leading-[21px] px-6 text-secondary_1 font-poppins-500 ${
                    buttonClassMapping[
                      freeStatusCount === 0 ? "sold_out" : status
                    ]
                  } py-[6px] rounded-full shadow-bundle-shadow`}
                  onClick={() => status === "locked" && setAmount(bundle.price)}
                >
                  {status === "locked"
                    ? `Donate $${bundle.price} to Unlock`
                    : capitalize(status)}
                </button>
                {status === "unlocked" && (
                  <div>
                    <img src="./images/lock_open.png" alt="lock" />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GameBundle;
