import React from "react";

const Footer = () => {
  return (
    <div className="bg-footer_bg text-secondary_1 mt-5 p-5 text-">
      <div className="container mx-auto flex sm:flex-row flex-col justify-between items-center">
        <span className="font-poppins-400">
          ©Copyright <a href="https://believeinyourself.ventures/">Believe In Yourself Ventures</a> (2024+)
        </span>
        <div className="flex gap-4">
          <span className="font-poppins-400">Terms & Conditions</span>
          <span>|</span>
          <span className="font-poppins-400">Privacy Policy</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
