import React from "react";

const Achievement = ({ achievement }: any) => {
  const { title, icon, details } = achievement;

  return (
    <div className="lg:w-[240px] xl:w-[302px] w-[302px] shadow-achievement-shadow lg:p-3 xl:p-5 p-5 rounded-[15px] flex flex-col gap-8">
      <div className="flex justify-between items-center">
        <span className="text-primary_2 lg:text-[16px] xl:text-[22px] text-[22px] lg:leading-[24px] xl:leading-[33px] leading-[33px] font-poppins-500">
          {title}
        </span>
        <div>
          <img src={icon} alt={title} className="w-[38px]" />
        </div>
      </div>
      <div className="w-100 flex flex-col lg:gap-4 gap-6">
        {details?.map((i: any, index: number) => {
          return (
            <div key={index} className="flex justify-between items-center">
              <span className="text-dark_gray lg:text-[12px] xl:text-[14px] text-[14px] lg:leading-[14px] xl:leading-[20px] leading-[20px] font-poppins-400 break-all">
                {i?.name}
              </span>
              <span className="text-primary_2 lg:text-[12px] xl:text-[14px] text-[14px] lg:leading-[14px] xl:leading-[20px] leading-[20px] font-poppins-500">
                {i.name === "Bundle Sold"
                  ? i.price
                  : `$${i?.price?.toFixed(2) || 0}`}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Achievement;
