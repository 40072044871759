import { useEffect } from 'react'

function DocumentTitle(title: string, metaDescription: string) {

  useEffect(() => {
    document.title = title;
    const elMetaDescription = document.querySelector('meta[name="description"]');
    if (elMetaDescription) elMetaDescription.setAttribute('content', metaDescription);
  }, [title]);
}
export default DocumentTitle