import React, { useEffect, useState } from "react";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import PaymentForm from "../payment-form/PaymentForm";

const PaymentBoardCard = ({
  campaignData,
  amount,
  setAmount,
  highestAmount,
}: any) => {
  const [activeIndex, setActiveIndex] = useState(1);
  const { donation } = campaignData;
  const [error, setError]: any = useState("");

  useEffect(() => {
    !amount && setAmount(donation?.default_donation || 0);
  }, [campaignData]);

  const handleAmountChange = (e: any) => {
    const value = e.target.value;
    !value || value < donation?.min_donation
      ? setError(`Minimum amount is ${donation?.min_donation} AUD`)
      : setError("");

    setAmount(value);
  };

  useEffect(() => {
    amount >= donation?.min_donation && setError("");
  }, [amount]);

  return (
    <div className="flex justify-center">
      <div className="lg:w-full md:w-[86vw] w-[86vw] sm:w-[86vw] shadow-achievement-shadow rounded-[15px] lg:p-5 sm:p-5 xl:p-[38px] p-[38px] flex flex-col gap-8">
        <div className="choose-amount flex flex-col gap-8">
          <p className="text-[25px] text-primary_2 font-poppins-500 pb-[20px]">
            Choose Charitable Donation
          </p>

          <div className="grid md:grid-cols-4 grid-cols-2 gap-8 lg:gap-4 xl:gap-4 pb-[20px]">
            {donation &&
              Object.keys(donation).map((key, index) => {
                const value = donation[key];
                return (
                  <button
                    key={index}
                    className={`font-poppins-500 text-[22px] lg:text-[18px] 2xl:text-[22px] leading-[33px] rounded-full border-[1.55px] py-2 focus:outline-none ${
                      Number(amount) === value
                        ? "border-primary_1 shadow-[0_0_8px_0_rgba(0,0,0,0.4)] text_dark_black"
                        : "border-transparent text-secondary_2"
                    } focus:border-primary_1 active:border-primary_1`}
                    onClick={() => {
                      setAmount(value);
                      setActiveIndex(index);
                    }}
                  >
                    {`$${value}`}
                  </button>
                );
              })}
            <button
              className={`py-2 font-poppins-500 rounded-full text-[22px] lg:text-[18px] 2xl:text-[22px] leading-[33px] mx-auto col-span-2 px-6 hover:bg-[#8E56EC] text-secondary_1 bg-gradient-to-r from-liner_1 to-liner_2 ${
                activeIndex === 6
                  ? "border-primary_1 shadow-[0_0_8px_0_rgba(0,0,0,0.4)] text_dark_black"
                  : ""
              }`}
              onClick={() => {
                setAmount(highestAmount + 1 || 0);
                setActiveIndex(6);
              }}
            >
              {" "}
              Best Highest
            </button>
          </div>
          <div>
            <Input
              id="standard-adornment-amount"
              type="number"
              startAdornment={
                <InputAdornment
                  position="start"
                  className="text-primary_2 !text-[80px] doller-icon"
                >
                  $
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment
                  position="end"
                  className="text-primary_2 text-[30px]"
                >
                  AUD
                </InputAdornment>
              }
              className="w-full !text-primary_2 !text-[30px] !font-poppins-500"
              onChange={handleAmountChange}
              value={amount}
              error={!!error}
            />
            {error && (
              <p className="text-[#FF0000] text-[14px] font-poppins-500 mt-2">
                {error}
              </p>
            )}
          </div>
        </div>
        <div className="details flex flex-col gap-8">
          <div className="form-details">
            <div className="form-details">
              <PaymentForm
                campaignData={campaignData}
                amount={amount}
                error={error}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentBoardCard;
