import React from "react";
import CloseIcon from "@mui/icons-material/Close";
const Modal = ({ open, setOpen, children }: any) => {
  return (
    <>
      {open && (
        <div
          id="myModal"
          className="modal fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-[15px]"
        >
          <div className="bg-white max-w-md modal-content p-8 rounded-[25px] shadow-lg sxl:w-[95vw] w-[540px]">
            <span className="close absolute top-0 right-0 mt-4 mr-4 text-gray-700 text-2xl cursor-pointer">
              &times;
            </span>
            <div className="flex justify-between items-center mb-8">
              <span className="text-[24px] text-primary_2 font-poppins-500">
                Payment Info
              </span>
              <div
                onClick={() => {
                  setOpen(false);
                }}
                className="font-poppins-500"
              >
                <CloseIcon />
              </div>
            </div>
            <div id="modal-content" className="modal-body">
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Modal;
