import React, { useEffect, useState } from "react";
import GameBundle from "../campaign/components/videos-bundles/GameBundle";
import DocumentTitle from "../../components/DocumentTitle";
import { getThankyouDetails } from "../../../service";
import { useParams } from "react-router-dom";

const Thankyou = () => {
  const [thankyouData, setThankyouData] = useState<any>(null);
  const { id } = useParams();

  useEffect(() => {
    getThankyouData();
  }, [id]);

  const getThankyouData = async () => {
    const responses: any = await getThankyouDetails(id);
    setThankyouData(responses?.data);
  };

  DocumentTitle("Donation page", "Successful Donation Page");

  return (
    <div className="thankyou container mx-auto my-[100px] p-5">
      <div className="bg-center">
        <div className="flex justify-center flex-col items-center">
          <img src="/images/thankyou/thankyou.png" alt="thankyou" />
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <div className="details flex justify-center mt-[60px] flex-col items-center">
          <span className="text-[50px] leading-[75px] text-dark_black text-center font-poppins-600">
            Donation Successful!
          </span>
          <span className="text-secondary_2 text-[15px] text-center font-poppins-500">
            Thanks to your incredible support, the path ahead seems brighter
            than ever.
          </span>
        </div>
        <div className="mx-auto mt-5 mb-4">
          <div className=" xl:w-[1020px] lg:w-[800px] md:w-[500px] w-[300px] border-b-2 border-light_low_gray"></div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center mt-8 gap-8">
        <div className="flex flex-col gap-4 justify-center items-center">
          <span className="text-dark_black text-[25px] text-center font-poppins-500">
            You can view the keys in the future by visting
          </span>
          <div className="border border-light_low_gray rounded-full px-8 py-2">
            <a
              href={thankyouData?.uuid_url}
              className="break-all font-poppins-400"
            >
              {thankyouData?.uuid_url}
            </a>
          </div>
        </div>
        <div className="bundle-img grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 gap-5">
          {thankyouData?.games &&
            thankyouData.games.length > 0 &&
            thankyouData?.games.map((i: any, index: number) => {
              return (
                <GameBundle bundle={i} key={index} currentPage="thankyou" />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
