import React from "react";

const Header = ({ campaignData, campaignReports }: any) => {
  const { name, description } = campaignData;
  const bundleDetails = campaignReports?.records?.slice(0, 2);

  return (
    <div>
      <div
        className={` ${
          !campaignData?.headerImage
            ? "bg-header-image"
            : "bg-center bg-cover bg-no-repeat"
        } h-[350px] w-100 `}
        style={
          campaignData?.headerImage && {
            backgroundImage: `url(${campaignData?.headerImage})`,
          }
        }
      >
        <div className="container mx-auto h-full flex px-6">
          <div className="flex flex-col justify-center gap-8 py-5 lg:w-[59%] xl:w-[59%] 2xl:w-[59%] md:w-full">
            <h1 className="text-secondary_1 text-[44px] xl:text-[50px] xl:leading-[60px] leading-[60px] font-poppins-600">
              {name}
            </h1>
            <p className="text-secondary_1 text-[18px] leading-[27px] font-poppins-500">
              {description}
            </p>
            <div>
              <span className="bg-secondary_1 break-all text-primary_2 text-[18px] leading-[27px] sm:px-8 px-4 py-2 rounded-full font-poppins-500">
                {(bundleDetails && bundleDetails[0]?.price) || 0} Bundle Sold. $
                {(bundleDetails && bundleDetails[1]?.price?.toFixed(2)) || 0.0}{" "}
                Raised!
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
