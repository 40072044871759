import React, { useState } from "react";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  cardOption,
  makeGamesObject,
  verifyGoogleCaptcha,
} from "../../../../utils";
import { makePayment } from "../../../../../service";
import { useNavigate } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const CheckoutForm = ({
  setOpen,
  values,
  amount,
  campaignData,
  charities,
}: {
  setOpen: (_arg1: boolean) => void;
  values: any;
  amount: any;
  campaignData: any;
  charities: any;
}) => {
  const [loading, setLoading] = useState(false);
  const elements: any = useElements();
  const stripe: any = useStripe();
  const [isValid, setIsValid]: any = useState(null);
  const [paymentError, setPaymentError]: any = useState(null);
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (isValid === null) {
      setPaymentError("Please enter valid details");
      setIsValid(false);
      return;
    }

    setLoading(true);

    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha("submit");
    const isCaptchaValid = await verifyGoogleCaptcha(token);
    if (!isCaptchaValid) {
      navigate("/error/404");
      return;
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (!error) {
      const games = await makeGamesObject(
        campaignData?.gamesDetails,
        Number(amount)
      );

      const response = await makePayment({
        amount: Number(amount),
        paymentMethodId: paymentMethod?.id,
        campaign_id: campaignData?._id,
        email: values?.email,
        name: values?.name,
        team_id: values?.team,
        games,
        charities,
      });

      if (response?.donationId) {
        setOpen(false);
        navigate(`/thankyou/${response?.donationId}`);
      } else {
        setPaymentError("Payment Failed !");
      }
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col border border-light_low_gray rounded-lg gap-6 p-3 mb-[10px]">
          <CardElement
            options={cardOption}
            onChange={(e) => {
              setIsValid(e.complete);
              e.complete
                ? setPaymentError("")
                : setPaymentError("Your card number is incomplete.");
            }}
          />
        </div>

        <div className="flex justify-center">
          <button
            className="font-poppins-500 rounded-lg sm:w-[400px] w-[250px] px-6 py-5 hover:bg-[#8E56EC] text-secondary_1 bg-gradient-to-r from-liner_1 to-liner_2"
            type="submit"
            disabled={isValid === false}
            onClick={handleSubmit}
          >
            {loading ? "Processing..." : "Pay"}
          </button>
        </div>
        {paymentError && (
          <span className="text-red-500 font-poppins-400">{paymentError}</span>
        )}
      </div>
    </form>
  );
};

const StripePromise = loadStripe(process.env.REACT_APP_PUBLISH_KEY as string);

const StripePaymentForm = ({
  setOpen,
  values,
  amount,
  campaignData,
  charities,
}: {
  setOpen: (_arg1: boolean) => void;
  values: any;
  amount: number;
  campaignData: any;
  charities: any;
}) => {
  return (
    <Elements stripe={StripePromise}>
      <CheckoutForm
        setOpen={setOpen}
        values={values}
        amount={amount}
        campaignData={campaignData}
        charities={charities}
      />
    </Elements>
  );
};

export default StripePaymentForm;
