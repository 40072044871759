import { verifyCaptcha } from "../../service";

export const achievement = [
  {
    title: "Numbers",
    icon: "./images/Numbers 1.png",
    key: "records",
  },
  {
    title: "Recent Donations",
    icon: "./images/Recent Donations 1.png",
    key: "recentDonations",
  },
  {
    title: "Top Contributors",
    icon: "./images/TopContributors 1.png",
    key: "topContributor",
  },
  {
    title: "Top Teams",
    icon: "./images/Group.png",
    key: "topTeams",
  },
];

export const buttonClassMapping: any = {
  locked: "bg-btn_primary_2",
  sold_out: "bg-btn_primary_3",
  unlocked: "bg-btn_primary_4",
};

export function qs(params: any) {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
}

export const capitalize = (s: string) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const separateWord = (input: string) => {
  return input.replace(/([a-z])([A-Z])/g, "$1 $2");
};

export const cardOption = {
  style: {
    base: {
      fontSize: "16px",
      color: "#424770",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

export const makeGamesObject = (games: any, amount: number) => {
  const values = games?.filter((game: any) => {
    return amount >= game?.price && game?.freeStatusCount !== 0;
  });
  return values;
};

export const verifyEmail = (email: string) => {
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return email.match(validRegex);
};

export const verifyGoogleCaptcha = async (token: string) => {
  const { score, success } = await verifyCaptcha(token);
  return success && score > 0.5 ? true : false;
};
