import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Thankyou from "./app/pages/thankyou";
import Campaign from "./app/pages/campaign/Campaign";
import Footer from "./app/components/Footer";
import ScrollToTop from "./app/components/ScrollToTop";
import Error from "./app/pages/error/Error";

function App() {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY as string}
    >
      <BrowserRouter>
        <ScrollToTop />
        <>
          <Routes>
            <Route path="/" element={<Campaign />} />
            <Route path="/:id" element={<Campaign />} />
            <Route path="thankyou/:id" element={<Thankyou />} />
            <Route path="error/404" element={<Error />} />
          </Routes>
          <Footer />
        </>
      </BrowserRouter>
    </GoogleReCaptchaProvider>
  );
}

export default App;
